import { FC, memo } from "react";

import cx from "apps/website/utils/misc/cx";

import { Size, sizeClassMap } from "./Spacer.map";

export interface ISpacer {
  size?: Size;
  customSize?: string;
  className?: string;
}

const Spacer: FC<ISpacer> = ({ size = "xl", className, customSize }) => (
  <div data-component="Spacer" className={cx("block w-full", !customSize ? sizeClassMap[size] : customSize, className)} />
);

export default memo(Spacer);
